import React from 'react';
import './Home.css'; // Add your styles in this CSS file

const Home: React.FC = () => {
    return (
        <div className="home-container">
            <header className="home-header">
                <h1>Welcome to My Portfolio</h1>
                <p>Hi, I'm Martin Džida, a software developer working in the automotive industry.</p>
            </header>

            <section className="home-links">
                <h2>Find me online:</h2>
                <ul>
                    <li>
                        <a href="https://www.linkedin.com/in/martin-džida-689003111" target="_blank" rel="noopener noreferrer">
                            LinkedIn
                        </a>
                    </li>
                    <li>
                        <a href="https://github.com/gdindzida" target="_blank" rel="noopener noreferrer">
                            GitHub
                        </a>
                    </li>
                    <li>
                        <a href="mailto:gdindzida@gmail.com">Email: gdindzida@gmail.com</a>
                    </li>
                </ul>
            </section>

            <footer className="home-footer">
                <p>&copy; 2024 Martin Džida</p>
            </footer>
        </div>
    );
};

export default Home;
